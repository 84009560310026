import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 0%;
  z-index: 2;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
        margin-top: 10%;

  }
`;

export const Content = styled.section`
  width: 90%;
  max-width: 1150px;
  display: flex;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: row;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.tertiary};

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    flex-direction: column;
    border-radius: 25px;
  }
`;

export const Heading = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: 60px;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    padding-bottom: 10px;
    padding-top: 30px;
  }
`;

export const Title = styled.span`
  font-size: 56px;
  text-align: left;
  font-weight: 700;
  line-height: 72px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  color: ${({ theme }) => theme.colors.white};

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
`;

export const TitleSub = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
  color: ${({ theme }) => theme.colors.white};

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-threeunits);
    font-size: 14px;
  }
`;

export const Button = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    color: ${({ theme }) => theme.colors.white};
    font-size: 15.5px;
    z-index: 10;
    font-style: normal;
    font-weight: 600;
    margin-left: 10px;
  }
`;

export const LinkModal = styled.span`
  color: rgb(255, 255, 255);
  font-size: 17.5px;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    font-size: 15px;
    font-weight: 800;
    line-height: 24px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  border-radius: 40px;
  overflow: hidden; /* Adicionado para garantir que a imagem não ultrapasse os limites do contêiner */

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    border-radius: 25px;
    height: 400px;
  }

  img {
    width: 100%;
    height: 600px; 
    object-fit: cover;
    border-radius: inherit;
    user-select: none;
    display: block; /* Adicionado para corrigir espaço extra no fundo da imagem */
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 400px; /* Ajuste conforme necessário */
 
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
  breakpoints.desktop}) {
    justify-content: center;
    align-items: center;
  }
  
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    justify-content: center;
    align-items: center;
  }
`;
