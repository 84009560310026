import styled from 'styled-components/macro';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const SectionHeader = styled.h1`
  font-size: 16px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.tertiary};

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    font-size: 16px;
    margin-left: 10px;
  }

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    margin-left: 10px;
  }
`;
