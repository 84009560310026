import styled from 'styled-components/macro';

export const Container = styled.section`
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 70px;

  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.quartiary};
  margin-top: 0%;
  text-align: center;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobile}) {
    padding-top: 20px);
    margin-top: 30%;
  }
`;

export const Content = styled.main`
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
`;

export const Header = styled.header`
  gap: var(--dl-space-space-threeunits);
  width: 60%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  justify-content: center;
  margin: 0 auto;
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    padding-bottom: var(--dl-space-space-fiveunits);
    margin-left: 0%;
    width: 100%;
  }
`;

export const Title = styled.h2`
  text-align: center;
  color: #ffffff;
  font-size: 40px;
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    padding: 0px;
    width: 100%;
  }
`;

export const Button = styled.div`
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const ButtonWhatsApp = styled.div`
  color: rgb(255, 255, 255);
  font-size: 17px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  line-height: 24px;
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: 800;
    line-height: 24px;
  }
`;

export const LinkWhatsapp = styled.span`
  color: rgb(255, 255, 255);
  font-size: 17.5px;
  
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    font-size: 15px;
    font-weight: 800;
    line-height: 24px;
  }
`;


export const ButtonCardapio = styled.div`
  color: rgb(255, 255, 255);
  font-size: 17px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  line-height: 24px;
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: 800;
    line-height: 24px;
  }
`;
export const LinkCardapio = styled.span`
  color: rgb(255, 255, 255);
  font-size: 17.5px;
  
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobile}) {
    font-size: 15px;
    font-weight: 800;
    line-height: 24px;
  }
`;
