import { useEffect, useState } from 'react';
import React from 'react';
import confetti from 'canvas-confetti';
import formatPrice from 'utils/formatPrice';
import { useCart } from 'contexts/cart-context';
import CustomSelect from './customSelect';
import { IProduct } from 'models';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Tooltip,
} from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { theme } from 'commons/style/theme';

interface IProps {
  product: IProduct;
}

interface DescriptionProps {
  description: string;
}

interface Option {
  value: string;
  label: string;
  price: number;
}

const ProductCard = ({ product }: IProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>();
  const [productPrice, setProductPrice] = useState<number>(product.price);
  const { openCart, addProduct } = useCart();

  const {
    sku,
    title,
    price,
    installments,
    currency_id,
    description,
    currency_format,
    is_schedule,
    waiting_schedule,
    has_product_type,
    types,
  } = product;

  const formattedPrice = formatPrice(productPrice, currency_id);

  useEffect(() => {
    if (has_product_type) {
      const initialPrice = selectedOption
        ? selectedOption.price
        : types[0]?.values[0]?.price || price;
      setProductPrice(initialPrice);
    } else {
      setProductPrice(price);
    }
  }, [selectedOption, has_product_type, types, price]);

  const handleAddProduct = () => {
    addProduct({
      ...product,
      quantity: 1,
      price: productPrice,
      selectedSubtype: selectedOption?.value || '',
    });
    openCart();
    confetti({ particleCount: 100, spread: 70 });
  };
  
  const Description: React.FC<DescriptionProps> = ({ description }) => {
    const maxLength = 100;
    const isTruncated = description.length > maxLength;
    const truncatedText = isTruncated
      ? description.substring(0, maxLength) + '...'
      : description;
  
    return (
      <Tooltip title={isTruncated ? description : ''} placement="bottom">
        <Typography
          component="div"
          variant="caption"
          sx={{ fontSize: '14px', paddingTop: '15px', paddingBottom: '10px' }}
        >
          {truncatedText}
        </Typography>
      </Tooltip>
    );
  };

  const renderInstallments = () => {
    if (installments) {
      const installmentPrice = productPrice / installments;
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          <Typography variant="body2" color={theme.colors.blackLow}>
            em até
          </Typography>
          <Typography variant="body1" color="#04a54f">
            <span>
              {installments}x {currency_format}
              {formatPrice(installmentPrice, currency_id)}{' '}
            </span>
          </Typography>
        </Box>
      );
    }
  };

  const renderProductTypeOptions = () => {
    return (
      has_product_type &&
      types.map((type) => (
        <Box key={type.id}>
          <Typography variant="overline">{type.name}</Typography>
          <CustomSelect
            options={type.values.map((value) => ({
              label: value.id,
              value: value.value,
              price: value.price,
            }))}
            onSelect={(option) => setSelectedOption(option)}
          />
        </Box>
      ))
    );
  };

  const renderAvailability = () => (
    <Box
      sx={{
        position: 'absolute',
        top: '8px',
        right: '8px',
        padding: '2px',
        fontSize: '0.75em',
        cursor: 'default',
        zIndex: 1,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          padding: '3px 6px',
          borderRadius: '0 8px 0 10px',
          backgroundColor: is_schedule ? theme.colors.primary : '#7CD033',
          color: theme.colors.white,
        }}
      >
        {is_schedule ? `Agendamento c/ ${waiting_schedule}` : 'Pronta entrega'}
      </Typography>
    </Box>
  );

  const images = (sku: number): string[] => {
    const getImageUrl1 = () =>
      process.env.REACT_APP_NODE_ENV === 'production'
        ? `${process.env.REACT_APP_AWS_S3_URL_PREFIX}/${process.env.REACT_APP_COMPANY_ID}-${sku}-1.webp`
        : `${require(`static/products/${sku}-1-product.webp`)}`;

    const getImageUrl2 = () =>
      process.env.REACT_APP_NODE_ENV === 'production'
        ? `${process.env.REACT_APP_AWS_S3_URL_PREFIX}/${process.env.REACT_APP_COMPANY_ID}-${sku}-2.webp`
        : `${require(`static/products/${sku}-2-product.webp`)}`;

    const listaImagens: string[] = [getImageUrl1(), getImageUrl2()];

    return listaImagens;
  };

  const imageList = images(sku);

  return (
    <Card
      sx={{
        width: { xs: '100%', sm: 260 },
        height: '100%',
        margin: 1,
        borderRadius: '10px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {renderAvailability()}
      <Carousel showThumbs={false} showStatus={false}>
        {imageList.map((image, index) => (
          <CardMedia
            sx={{ padding: '10px', borderRadius: '20px' }}
            component="img"
            height="220"
            image={image}
            alt={`Imagem ${index + 1}`}
            key={index}
          />
        ))}
      </Carousel>
      <CardContent sx={{ flexGrow: 1, position: 'relative' }}>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="inherit"
            component="div"
            sx={{ color: theme.colors.black, fontSize: '15px' }}
          >
            {title}
          </Typography>
        </Box>
        <Description description={description} />
        <Typography variant="h5" color={theme.colors.blackLow}>
          <small>{currency_format}</small>
          <b>{formattedPrice.slice(0, -3)}</b>
          <span style={{fontSize: 15}}>{formattedPrice.slice(-3)}</span>
        </Typography>
        <Box>{renderInstallments()}</Box>
        <Box sx={{ mt: 2, mb: 5 }}>{renderProductTypeOptions()}</Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 16,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '16px',
            backgroundColor: '#fff',
          }}
        >
          <Button
            variant="contained"
            onClick={handleAddProduct}
            size="small"
            disabled={has_product_type && !selectedOption}
            style={{
              backgroundColor:
                has_product_type && !selectedOption
                  ? theme.colors.secondary
                  : theme.colors.primary,
              padding: 5,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              fontSize: 12,
              color: theme.colors.white,
            }}
          >
            {has_product_type && !selectedOption
              ? 'Selecione uma opção'
              : 'Adicionar ao carrinho'}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
