import { IProduct } from 'models';
import * as S from './style';
import { Box } from '@mui/material';
import ProductCard from './Product/productCard';
import { useMemo } from 'react';

interface IProps {
  products: IProduct[];
}

const Products = ({ products }: IProps) => {
  const groupedProducts = useMemo(() => {
    const grouped: { [key: string]: IProduct[] } = {};
    products.forEach((p) => {
      const category = p.category.name;
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(p);
    });
    return grouped;
  }, [products]);

  const renderGroupedProducts = () => {
    return Object.keys(groupedProducts).map((category) => (
      <Box key={category}>
        <S.SectionHeader>{`${category}`}</S.SectionHeader>
        <S.Container>
          {groupedProducts[category].map((p) => (
            <ProductCard product={p} />
          ))}
        </S.Container>
      </Box>
    ));
  };

  return <>{renderGroupedProducts()}</>;
};

export default Products;
