import React, { useState } from "react";

import * as S from './style';

import Card from "components/Soluctions/Card/Card";
import { UseModalFacilityOrder } from "contexts/facility-context/useModalFacilityOrder";
import { Container } from "@mui/material";

const Soluctions = () => {
  const [open, setOpen] = useState(false);


  return (
    <Container id="solutions">
      <S.Header>
        <S.Title>
          Crie o seu bolo confeitado com o nosso criador inteligente
        </S.Title>
      </S.Header>
      <S.CardContent onClick={() => setOpen(true)}>
        <Card
          Icon={require("static/cardsIcons/01.png")}
          Header="Escolher o tipo do bolo"
          IconAlt="WebSites responsivos, com design moderno e otimizados para os mecanismos de busca. "
        ></Card>
        <Card
          Icon={require("static/cardsIcons/02.png")}
          Header="Selecione o recheio"
          IconAlt="Site de venda + plataforma completa de gestão de produtos, vendas e pedidos para a sua confeitaria"
        ></Card>
        <Card
          Icon={require("static/cardsIcons/03.png")}
          Header="Selecione a decoração"
          IconAlt="Desenvolvemos softwares para gestão de empresas, comércios e indústrias."
        ></Card>
        <Card
          Icon={require("static/cardsIcons/04.png")}
          Header="Agende a data de entrega"
          IconAlt="Desenvolvemos softwares para gestão de empresas, comércios e indústrias."
        ></Card>
      </S.CardContent>
      {open && <UseModalFacilityOrder setOpen={setOpen} open={open} />}
    </Container>
  );
};

export default Soluctions;
