import React from 'react';
import { Toolbar, Typography, Badge, Tooltip, Box } from '@mui/material';
import { NotificationsOutlined as NotificationsOutlinedIcon } from '@mui/icons-material';

interface MenuDesktopContentProps {
  openStore: boolean;
}

const MenuDesktopContent: React.FC<MenuDesktopContentProps> = ({
  openStore,
}) => {
  return (
    <Box
      style={{ borderRadius: '10px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
    >
      <Toolbar>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ marginRight: '20px' }}>
            <Typography variant="body1">
              Estamos entregando em: <strong>Itajaí e Região</strong>
            </Typography>
            <Typography variant="subtitle2" style={{color: "#4F881D", fontWeight: "700"}}>
              Taxa de R$15,00 reais
            </Typography>
          </Box>
          <Tooltip
            title={openStore ? 'Fecha às 18:00' : 'Abre amanhã às 8:00'}
            color={openStore ? 'success' : 'error'}
          >
            <Badge
              variant="dot"
              color={openStore ? 'success' : 'error'}
              overlap="circular"
            >
              <NotificationsOutlinedIcon />
            </Badge>
          </Tooltip>
          <Typography
            variant="body2"
            color={openStore ? 'success' : 'error'}
            style={{ marginLeft: '0px', fontWeight: 'bold' }}
          >
            {openStore ? 'LOJA ABERTA' : 'LOJA FECHADA'}
          </Typography>
        </Box>
      </Toolbar>
    </Box>
  );
};

export default MenuDesktopContent;
