import { useEffect, useState } from 'react';
import { useProducts } from 'contexts/products-context';
import { getCategories } from 'services/categories';
import Chip from '@material-ui/core/Chip';
import { ICategory } from 'models';
import { Box, Typography } from '@mui/material';
import { theme } from 'commons/style/theme';

const Filter = () => {
  const { filters, filterProducts } = useProducts();
  const [categories, setCategories] = useState([]);
  const selectedCheckboxes = new Set(filters);

  useEffect(() => {
    const fetchCategories = async () => {
      const categories = await getCategories();
      setCategories(categories);
    };

    fetchCategories();
  }, []);

  const toggleCheckbox = (event: React.MouseEvent<HTMLDivElement>) => {
    const label = (event.currentTarget as HTMLElement).innerText;
    if (selectedCheckboxes.has(label)) {
      selectedCheckboxes.delete(label);
    } else {
      selectedCheckboxes.add(label);
    }

    const filters = Array.from(selectedCheckboxes);
    filterProducts(filters);
  };

  const createCheckbox = (category: ICategory) => {
    const isSelected = selectedCheckboxes.has(category.name);
    return (
      <Chip
        label={category.name}
        onClick={toggleCheckbox}
        key={category.id}
        variant="default"
        style={{
          marginBottom: 5,
          background: isSelected ? theme.colors.primary : theme.colors.white,
          color: isSelected ? theme.colors.white : theme.colors.primary,
          borderColor: theme.colors.primary,
          borderWidth: '1px',
          borderStyle: 'dotted',
        }}
      />
    );
  };

  return (
    <Box>
      <Typography variant='inherit'>Opções:</Typography>
      <Box mt={3} style={{ display: 'flex', flexDirection: 'column', width: 'auto'}}>
        {categories.map(createCheckbox)}
      </Box>
    </Box>
  );
};

export default Filter;
