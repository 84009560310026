/* eslint-disable jsx-a11y/img-redundant-alt */
import * as S from './style';

const ActionBar = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Heading>
          <S.Title>É Sobre Colecionar Memórias</S.Title>
          <S.TitleSub>
            "Cada doce é mais do que um sabor delicioso; é a materialização de
            momentos especiais. Colecione cada mordida, cada risada e cada
            memória."
          </S.TitleSub>
          <S.Button className="template-button">
            <a href="./" style={{ textDecoration: "none" }}> <S.LinkModal> Fazer uma encomenda </S.LinkModal> </a>
          </S.Button>
        </S.Heading>
        <S.Image>
          <S.ImageWrapper>
            <img
              alt="image"
              src={require('static/actionbar.png')}
            />
          </S.ImageWrapper>
        </S.Image>
      </S.Content>
    </S.Container>
  );
};

export default ActionBar;
