import * as S from './style';
import Alert from '@mui/material/Alert';
import { SetStateAction, useState } from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState<React.ReactNode | null>(null);

  const handleEmailChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setEmail(e.target.value);
  };

  const closeAlert = () => {
    setAlert(null);
  };

  const handleSubscribeClick = async () => {
    try {
      setAlert(
        <Alert
          severity="info"
          onClose={closeAlert}
          style={{ position: 'fixed', top: 16, right: 16, zIndex: 9999 }}
        >
          Aguarde até que o processo se conclua
        </Alert>
      );

      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      setTimeout(() => {
        if (response.ok) {
          setAlert(
            <Alert
              severity="success"
              // description="Agora você receberá nossas novidades por e-mail."
              onClose={closeAlert}
              style={{ position: 'fixed', top: 16, right: 16, zIndex: 9999 }}
            >
              Cadastro realizado com sucesso!{' '}
            </Alert>
          );
        } else {
          setAlert(
            <Alert
              severity="error"
              // description="Ocorreu um erro ao se inscrever. Por favor, tente novamente mais tarde."
              onClose={closeAlert}
              style={{ position: 'fixed', top: 16, right: 16, zIndex: 9999 }}
            >
              Erro ao cadastrar o e-mail.
            </Alert>
          );
        }
      }, 3000);
    } catch (error) {
      setAlert(
        <Alert
          severity="error"
          // description="Ocorreu um erro ao se inscrever. Por favor, tente novamente mais tarde."
          onClose={closeAlert}
          style={{ position: 'fixed', top: 16, right: 16, zIndex: 9999 }}
        >
          Erro ao cadastrar o e-mail.
        </Alert>
      );
    }
  };
  return (
    <S.Container>
      {alert}
      <S.Content>
        <S.Heading>
          <S.Title> Inscreva-se em nossa Newsletter </S.Title>
          <S.DescriptionEmail>
            <S.NewsletterInput
              type="email"
              placeholder="Informe o seu melhor e-mail"
              value={email}
              onChange={handleEmailChange}
            />
            <S.Buttom1>
              <S.Enviar onClick={handleSubscribeClick}> Enviar </S.Enviar>
            </S.Buttom1>
          </S.DescriptionEmail>
          <S.SubTitle>
            Ao assinar nossa newsletter você concorda com nossos Termos e
            Condições
          </S.SubTitle>
          <S.Contact>
            <S.ContactEmail> Contato </S.ContactEmail>
            <S.ContactLink href="mailto:santaanna@evoluinfo.com.br?subject=Duvidas">
              santaanna@evoluinfo.com.br
            </S.ContactLink>
          </S.Contact>
        </S.Heading>
        <S.Right>
          <S.Category>
            <S.TitleCategory> Produtos </S.TitleCategory>
            <S.MainCategory>
              <S.ItemCategory>Bolos em geral</S.ItemCategory>
              <S.ItemCategory>Docinhos</S.ItemCategory>
              <S.ItemCategory>Sobremesas especiais</S.ItemCategory>
              <S.ItemCategory>Tortas</S.ItemCategory>
            </S.MainCategory>
          </S.Category>
          <S.Category>
            <S.TitleCategory> Kit festas </S.TitleCategory>
            <S.MainCategory>
              <S.ItemCategory>Empresas</S.ItemCategory>
              <S.ItemCategory>Mêsversario</S.ItemCategory>
              <S.ItemCategory>Cestas de café</S.ItemCategory>
            </S.MainCategory>
          </S.Category>
          <S.Category>
            <S.TitleCategory> Datas comemorativas </S.TitleCategory>
            <S.MainCategory>
              <S.ItemCategory>
                Páscoa com Jesus e muuuito chocolate
              </S.ItemCategory>
              <S.ItemCategory>Dia das mães com muuuito amor</S.ItemCategory>
              <S.ItemCategory>
                Dia dos namorados com muuuita paixão
              </S.ItemCategory>
              <S.ItemCategory>Natal com muuuito panetone</S.ItemCategory>
            </S.MainCategory>
          </S.Category>
        </S.Right>
      </S.Content>
      <S.Copyright>
        <S.TextCopyright>
          Copyright © Santa Anna Confeitaria Artesanal - {currentYear}{' '}
        </S.TextCopyright>
        <S.TextPlataform>
          Plataforma criada com tecnologia
          <S.Createdy
            rel="noreferrer"
            href="http://evoluinfo.com.br/platform"
            target="_blank"
          >
            EvoluInfo - Candy Store Platform
          </S.Createdy>
        </S.TextPlataform>
      </S.Copyright>
    </S.Container>
  );
};

export default Footer;
