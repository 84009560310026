import React, { useState } from 'react';
import { Box, Chip } from '@mui/material';
import { theme } from 'commons/style/theme';

interface Option {
  value: string;
  label: string;
  price: number;
}

interface CustomSelectProps {
  options: Option[];
  onSelect: (option: Option) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, onSelect }) => {
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleSelectOption = (option: Option) => {
    setSelectedValue(option.value);
    onSelect(option);
  };

  return (
    <Box>
      {options.map((option, index) => (
        <Chip
          label={option.value}
          variant="outlined"
          key={index}
          onClick={() => handleSelectOption(option)}
          style={{
            marginBottom: 5,
            background: selectedValue === option.value ? theme.colors.primary : theme.colors.white,
            color: selectedValue === option.value ? theme.colors.white : theme.colors.primary,
            borderColor: selectedValue === option.value ? theme.colors.primary : theme.colors.primary,
            borderWidth: '0.2px',
            borderStyle: 'solid',
            marginRight: 3,
          }}
        />
      ))}
    </Box>
  );
};

export default CustomSelect;
